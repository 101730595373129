import { useMemo } from "react";
import { Popover } from "@components/common/Popover";
import Select from "react-select";
import { McaAccountJobsInfo } from "@components/admin/mca-accounts";
import { formatDate } from "@app/utils/formatDate";
import { removeUnderscores } from "@app/utils/removeUnderScores";
import { McaAccountTable } from "@components/admin/mca-accounts";
import { QaDbApiResponse } from "@app/types";
import {
  CreateMcaAccountPayload,
  McaAccount,
} from "@app/store/api/mcaAccount/types";
import classNames from "classnames";
import { McaAccountStatus } from "@app/store/api/mcaAccount/types";
import { toast } from "react-hot-toast";
import { useGetAllUsersQuery } from "@app/store/api/qaUsersApi";
import { useUpdateMcaAccountMutation } from "@app/store/api/mcaAccount/mcaAccountsApi";

interface McaAccountDataProps {
  data?: QaDbApiResponse<McaAccount[]>;
  skip: number;
  setSkip: (skip: number) => void;
  isError: boolean;
  isQa: boolean;
}

export const McaAccountData = ({
  data,
  skip,
  setSkip,
  isError,
  isQa,
}: McaAccountDataProps) => {
  const { data: qaUsers } = useGetAllUsersQuery(undefined, {
    skip: isQa,
  });
  const [updateMcaAccount] = useUpdateMcaAccountMutation();

  const qaUsersWithNameAndId = useMemo(() => {
    if (!qaUsers) return [];

    return qaUsers.response_data.map((el) => ({
      label: el.username,
      value: el.id,
    }));
  }, [qaUsers]);

  const onUpdateMcaAccount = async (
    payload: CreateMcaAccountPayload & {
      mca_account_id: string;
    },
  ) => {
    try {
      await updateMcaAccount({
        ...payload,
        mca_account_id: payload.mca_account_id,
      }).unwrap();
      toast.success("MCA account updated successfully");
    } catch {
      toast.error("Error updating the MCA account");
    }
  };

  const accountsData = useMemo(
    () =>
      data?.response_data?.map((account) => ({
        name: account?.name ?? "-",
        status: (
          <span className={getMcaAccountStatusBadge(account?.status).className}>
            {getMcaAccountStatusBadge(account?.status).label}
          </span>
        ),
        totalTasks: (
          <Popover content={<McaAccountJobsInfo accountId={account?.id} />}>
            <span className="text-gray-700 border border-gray-200 rounded-md px-2 py-1 hover:bg-gray-100 cursor-pointer">
              {account?.total_tasks ?? "-"}
            </span>
          </Popover>
        ),
        mcaVersion: account?.mca_version
          ? removeUnderscores(account.mca_version)
          : "-",
        accountOwner: account?.account_owner_name || "-",
        createdAt: formatDate(account?.created_at) || "-",
        assign: (
          <Select
            className="min-w-30 max-w-30"
            options={qaUsersWithNameAndId}
            menuPortalTarget={document.body}
            styles={{
              menuPortal: (base) => ({
                ...base,
                zIndex: 100,
              }),
              control: (base) => ({
                ...base,
                width: "220px",
              }),
              menu: (base) => ({
                ...base,
                width: "220px",
              }),
            }}
            onChange={(e) => {
              if (e?.value) {
                onUpdateMcaAccount({
                  account_owner: e?.value,
                  mca_account_id: account.id,
                  name: account.name,
                  status: account.status,
                  mca_version: account.mca_version,
                });
              }
            }}
          />
        ),
      })) ?? [],
    [data, qaUsersWithNameAndId],
  );

  return (
    <McaAccountTable
      isQa={isQa}
      data={accountsData}
      total={data?.total || 0}
      skip={skip}
      setSkip={setSkip}
      isError={isError}
      limit={10}
    />
  );
};

interface StatusConfig {
  label: string;
  color: string;
}

const statusConfig: Record<McaAccountStatus, StatusConfig> = {
  [McaAccountStatus.ALLOCATED]: {
    label: "Allocated",
    color: "bg-yellow-100 text-yellow-800",
  },
  [McaAccountStatus.FREE]: {
    label: "Free",
    color: "bg-green-100 text-green-800",
  },
  [McaAccountStatus.IN_USE]: {
    label: "In Use",
    color: "bg-blue-100 text-blue-800",
  },
  [McaAccountStatus.LOCKED]: {
    label: "Locked",
    color: "bg-red-100 text-red-800",
  },
};

export function getMcaAccountStatusBadge(
  status: McaAccountStatus,
  className = "",
) {
  const config = statusConfig[status] || {
    label: status,
    color: "bg-gray-100 text-gray-800",
  };

  const badgeClasses = classNames(
    "inline-flex",
    "items-center",
    "px-2.5",
    "py-0.5",
    "rounded-sm",
    "text-xs",
    "font-medium",
    config.color,
    className,
  );

  return {
    label: config.label,
    className: badgeClasses,
  };
}
